import request from '@/utils/request.js'

// define header config
const config = {
    headers: {
        'Content-Type': 'application/json'
    }
}

function validate() {
    const content = {}
    return request.post('/validate', content, config)
}

function signup() {
    const content = {}
    return request.post('/signup', content, config)
}

function pay(count) {
    const content = {
        'months': count
    }
    return request.post('/pay', content, config)
}

function checkPaid() {
    const content = {}
    return request.post('/check/paid', content, config)
}

function deleteHistory() {
    const content = {}
    return request.post('/delete/history', content, config)
}

function sendMessage(msg, chatType) {
    const content = {
        'content': msg,
        'chatType': chatType
    }
    return request.post('/send/message', content, config)
}

export default {
    validate,
    signup,
    pay,
    checkPaid,
    deleteHistory,
    sendMessage
}
