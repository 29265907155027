<template>
  <div class="page">
    <div class="chatBox">
      <div class="k" v-show="setSignUp">
        <div class="title">注册</div>
        <!--        <input class="input-name" placeholder="input your name..." v-model="signUpName">-->
        <div class="input-submit">
          <button class="yes" @click="confirmSignUp()">确认</button>
          <button class="no" @click="cancelSignUp()">取消</button>
        </div>
      </div>
      <div class="p" v-show="setPayMoney">
        <img alt="" width="300" height="300" src="./assets/reward.jpg" @click="hidePayMoney()"/>
      </div>
      <div class="q" v-show="setShopCart">
        <div class="title">Shop Cart</div>
        <input class="input-months" placeholder="请输入购买月数" v-model="count"
               oninput="value=value.replace(/[^\d]/g,'')">
        <div class="input-submit">
          <button class="yes" @click="confirmShopCart()">confirm</button>
          <button class="no" @click="cancelShopCart()">cancel</button>
        </div>
      </div>
      <div class="fence">
        <div class="head">
          <img src="./assets/headimage.jpg" alt=""/>
        </div>
        <ul>
          <li class="iconfont icon-black-money" @click="showPayMoney()"/>
          <li class="iconfont icon-remove-black" @click="deleteHistory()"/>
          <li v-if="this.signedFlag" class="iconfont icon-black-signed"/>
          <li v-else class="iconfont icon-black-signup" @click="showSignUpPage()"/>
          <!--          <li class="iconfont icon-black-cart" @click="showShopCartPage()"/>-->
        </ul>
      </div>
      <div class="face">
        <div class="one">Chat With AI</div>
        <div class="two">
          <ul class="answer" id="chatWindows">
            <li v-for="(item, index) in information" :key="index">
              <span :class="{ ans2: index % 2 ? 1 : 0 }" v-html="item"/>
            </li>
          </ul>
        </div>
        <div class="three">
          <select class="selected" v-model="chatType" style="font-weight: bold">
            <option value="1">gpt3.5</option>
            <option value="2">千问</option>
          </select>
          <textarea cols="70" rows="2" placeholder="say hello..." v-model="sub" @keyup.enter="handleEnter($event)"
                    :disabled="isDisabled"/>
          <div class="center iconfont icon-black-send" @click="sendMessage()" @keyup.enter="sendMessage()"
               :disabled="isDisabled"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/api/chatApi'
import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

export default {
  name: 'App',
  data() {
    return {
      setSignUp: false,
      setShopCart: false,
      setPayMoney: false,
      setDeleteHistory: false,
      signedFlag: false,
      chatType: '1',
      sub: '',
      count: '',
      isDisabled: true,
      information: ['每日第一次使用请先点击左下角图标进行注册，一个IP每日只有10次免费聊天机会。']
      // information: ['每日第一次使用请先点击左下角图标进行注册，一个IP每日只有10次免费聊天机会。<br>体验价2元/月购买后，可享受每日10次免费聊天机会。']
    }
  },
  // once open the website, validate current user signed up or not
  created() {
    this.validate();
  },
  methods: {
    showSignUpPage() {
      this.setSignUp = true
    },
    showShopCartPage() {
      this.setShopCart = true
    },
    showPayMoney() {
      this.setPayMoney = true
      this.isDisabled = true
    },
    hidePayMoney() {
      this.setPayMoney = false
      this.isDisabled = this.signedFlag !== true
    },
    deleteHistory() {
      this.$confirm("清除对话记录？", "", "info").then(() => {
        api.deleteHistory().then(rsp => {
          if (rsp && rsp.data.code === 0) {
            this.information = ['每日第一次使用请先点击左下角图标进行注册，一个IP每日只有10次免费聊天机会。']
            this.$alert("清除成功。", "", "success")
          } else {
            this.$alert(rsp.data.description, "", "error")
          }
        })
      })
    },
    cancelSignUp() {
      this.setSignUp = false
    },
    cancelShopCart() {
      this.setShopCart = false
    },
    validate() {
      api.validate().then(rsp => {
        if (rsp && rsp.data.code === 0) {
          this.setSignUp = false
          this.signedFlag = true
          this.isDisabled = false
          if (rsp.data.description !== '') {
            for (const history of rsp.data.description) {
              this.information.push(history.replaceAll('\n', '<br/>'))
            }
          }
        }
      })
    },
    confirmSignUp() {
      api.signup().then(rsp => {
        if (rsp && rsp.data.code === 0) {
          this.signedFlag = true
          this.isDisabled = false
          this.$alert("注册成功。", "", "success")
        } else {
          this.$alert("注册失败，请重试。", "", "error")
          this.isDisabled = true
        }
      })
      this.setSignUp = false
    },
    confirmShopCart() {
      if (!this.signedFlag) {
        this.$alert("请先点击左下角进行注册。", "", "info")
        this.setShopCart = false
        return
      }
      if (this.count === '' || parseInt(this.count) < 1) {
        this.$alert("购买月数请输入一个正整数。", "", "warning")
        return
      }
      api.pay(this.count).then(rsp => {
        if (rsp && rsp.data.code === 0) {
          window.open(rsp.data.description)
          this.$confirm("支付完成？", "", "info").then(() => {
            api.checkPaid().then(res => {
              if (res && res.data.code === 0) {
                this.$alert("购买成功。", "", "success")
              } else {
                this.$alert("请支付完成后再点击确认。", "", "warning")
              }
            })
          }).catch(() => {
            this.$alert("购买失败，请重试。", "", "error")
          })
        } else {
          this.$alert("购买失败，请重试。", "", "error")
        }
      })
      this.setShopCart = false
    },
    sendMessage() {
      if (this.sub !== '') {
        // when displaying in windows, needed to repalce the '\n' with '<br/>'
        this.information.push(this.sub.replaceAll('\n', '<br/>'))
        this.isDisabled = true
        api.sendMessage(this.sub, this.chatType).then(rsp => {
          if (rsp) {
            this.information.push(rsp.data.description.replaceAll('\n', '<br/>'))
          }
          this.isDisabled = false
          // once the information updated, refresh the chat windows to the bottom
          this.$nextTick(() => {
            let windows = document.getElementById('chatWindows')
            windows.scrollTop = windows.scrollHeight
          })
        })
        this.sub = ''
      }
    },
    handleEnter(event) {
      // ctr + enter: new line, enter: send message
      if (event.keyCode === 13 && event.ctrlKey) {
        this.sub = this.sub + '\n'
      } else if (event.keyCode === 13) {
        // disable enter to new line
        event.preventDefault();
        this.sendMessage();
      }
    }
  },
  components: {}
}
</script>

<style lang="less" scoped>
.page {
  margin: 0;
  padding-top: 1px;
  background: url(./assets/background.jpg);
  background-size: cover;
  height: 710px;
}

.chatBox {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.1);
  overflow: hidden;
  border-radius: 5px;

  .k {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 80px;
    border-radius: 10px;
    border: 1px solid rgba(1, 1, 1, 0.4);

    .title {
      height: 50%;
      font-size: 140%;
      text-align: center;
      background-color: rgba(1, 1, 1, 0.4);
      border-radius: 10px 10px 0 0;
      color: aliceblue;
    }

    .input-submit {
      height: 50%;
      text-align: center;
      background-color: rgba(1, 1, 1, 0.4);
      border-radius: 0 0 10px 10px;
      color: antiquewhite;

      .yes {
        width: 50%;
        height: 100%;
        font-size: 105%;
        text-align: center;
        background-color: rgba(1, 1, 1, 0.4);
        border-radius: 0 0 0 10px;
        color: antiquewhite;
      }

      .yes:hover {
        cursor: pointer;
        background-color: rgba(1, 1, 1, 0.6);
      }

      .no {
        width: 50%;
        height: 100%;
        font-size: 105%;
        text-align: center;
        background-color: rgba(1, 1, 1, 0.4);
        border-radius: 0 0 10px 0;
        color: antiquewhite;
      }

      .no:hover {
        cursor: pointer;
        background-color: rgba(1, 1, 1, 0.6);
      }
    }
  }

  .p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .q {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 100px;
    border-radius: 10px;
    border: 1px solid rgba(1, 1, 1, 0.4);

    .title {
      height: 40%;
      font-size: 120%;
      text-align: center;
      background-color: rgba(1, 1, 1, 0.4);
      border-radius: 10px 10px 0 0;
      color: aliceblue;
    }

    .input-months {
      width: 100%;
      height: 30%;
      font-size: 105%;
      text-align: center;
    }

    .input-submit {
      height: 30%;
      text-align: center;
      background-color: rgba(1, 1, 1, 0.4);
      border-radius: 0 0 10px 10px;
      color: antiquewhite;

      .yes {
        width: 50%;
        height: 100%;
        font-size: 105%;
        text-align: center;
        background-color: rgba(1, 1, 1, 0.4);
        border-radius: 0 0 0 10px;
        color: antiquewhite;
      }

      .yes:hover {
        cursor: pointer;
        background-color: rgba(1, 1, 1, 0.6);
      }

      .no {
        width: 50%;
        height: 100%;
        font-size: 105%;
        text-align: center;
        background-color: rgba(1, 1, 1, 0.4);
        border-radius: 0 0 10px 0;
        color: antiquewhite;
      }

      .no:hover {
        cursor: pointer;
        background-color: rgba(1, 1, 1, 0.6);
      }
    }
  }

  .fence {
    float: left;
    width: 32px;
    height: 100%;
    background-color: rgba(1, 1, 1, 0.4);

    .head {
      width: 100%;
      height: 85%;
      padding: 0 0 0 0;

      img {
        width: 30px;
        height: 30px;
        border-radius: 10px;
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        text-align: center;
        color: aliceblue;
        font-size: 25px;
        width: 100%;
        height: 36px;

        p {
          height: 20px;
          text-align: center;
          background-color: rgba(1, 1, 1, 0.4);
          border-radius: 10px 10px 0 0;
          color: aliceblue;
        }
      }

      li:hover {
        color: red;
        cursor: pointer;
      }
    }
  }

  .face {
    overflow: hidden;

    .one {
      height: 35px;
      font-size: 120%;
      text-align: center;
      background-color: rgba(1, 1, 1, 0.6);
      color: aliceblue;
      line-height: 40px;
    }

    .two {
      height: 635px;

      .answer {
        height: 100%;
        padding: 10px 0;
        width: 100%;
        list-style: none;
        overflow: scroll;

        li {
          float: left;
          width: 100%;
          margin-bottom: 10px;

          span {
            background-color: rgba(1, 1, 1, 0.3);
            display: inline-block;
            padding: 5px;
            margin: 0 10px;
            border-radius: 5px;
            color: aliceblue;
          }
        }

        .ans2 {
          float: right;
        }
      }

      .answer::-webkit-scrollbar {
        width: 6px;
        background-color: #f8f8f800;
      }
    }

    .three {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      height: 35px;
      background-color: white;

      .selected {
        border-radius: 3px;
        border-color: lightgray;
      }

      textarea {
        float: left;
        width: 85%;
        font-size: 110%;
        resize: none;
        padding: 0;
        border: none;
        outline: none;
      }

      .center {
        overflow: hidden;
        line-height: 40px;
        width: 15%;
        font-size: 25px;
        text-align: center;
      }

      .center:hover {
        color: red;
        cursor: pointer;
      }
    }
  }
}
</style>
